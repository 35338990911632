<template>
    <ul class="menu-list">
        <draggable :list="children" :group="{name: 'resources'}">
        <li class="menu-chapter-list list-item" v-for="(el,index) in children" :key="el.name+'_'+index" v-if="el != null">
            <div class="chapter-content" v-if="el.type === 1 || el.type === '1'">
                <div class="item-content" v-if="el.status">
                    <span class="section_index">第{{index+1}}章：</span>
                    <el-input v-model="name" :ref="'section_'+index" @blur="checkSection(index)" placeholder="请输入章"></el-input>
                </div>
                <div class="item-content" v-else>第{{index+1}}章：{{el.name}}</div>
                <div class="item-actions">
                    <div class="icon-btn-expand">
                        <el-dropdown  trigger="click" @command="handleCommand">
                            <span class="el-dropdown-link">
                                <i class="el-icon-plus"></i>添加
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="index">添加节</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <a class="icon-btn" @click="editName(index)">
                        <span class="actions-btn">
                            <i class="el-icon-edit"></i>编辑
                        </span>
                    </a>
                    <template>
                        <el-popconfirm
                        title="确定删除吗?"
                        @confirm="delSt(index)"
                        >
                            <a class="icon-btn" slot="reference">
                                <span class="actions-btn">
                                    <i class="el-icon-delete"></i>删除
                                </span>
                            </a>
                        </el-popconfirm>
                    </template>
                    <a class="icon-btn" @click="expandFunc(index)">
                        <span class="actions-btn" v-if="!el.expand">
                            <i class="el-icon-caret-top"></i>收起
                        </span>
                        <span class="actions-btn" v-else>
                            <i class="el-icon-caret-bottom"></i>展开
                        </span>
                    </a>
                </div>
            </div>
            <div class="chapter-content" v-if="el.type === 2 || el.type === '2'">
                <div class="item-content" v-if="el.status">
                    <span class="section_index">第{{index+1}}节：</span>
                    <el-input v-model="name" :ref="'section_'+index" @blur="checkSection(index)" placeholder="请输入节"></el-input>
                </div>
                <div class="item-content" v-else>第{{index+1}}节：{{el.name}}</div>
                <div class="item-actions">
                    <a class="icon-btn" @click="editName(index)">
                        <span class="actions-btn">
                            <i class="el-icon-edit"></i>编辑
                        </span>
                    </a>
                    <template>
                        <el-popconfirm
                        title="确定删除吗?"
                        @confirm="delSt(index)"
                        >
                            <a class="icon-btn" slot="reference">
                                <span class="actions-btn">
                                    <i class="el-icon-delete"></i>删除
                                </span>
                            </a>
                        </el-popconfirm>
                    </template>
                </div>
            </div>
            <div class="chapter-content" v-if="el.type === 3 || el.type === '3'">
                <div class="item-content" v-if="el.status">
                    <span class="section_index">资源{{index+1}}：</span>
                    <el-input v-model="name" :ref="'section_'+index" @blur="checkSection(index)" placeholder="请输入"></el-input>
                </div>
                <div class="item-content" v-else><i v-html="fileTypeF(el.resource_file_type)"></i>资源{{index+1}}：{{el.name}} <span v-if="el.resource_page" style="padding-left: 5px;">({{el.resource_page}})</span></div>
                <div class="item-actions">
                    <a class="icon-btn" @click="editName(index)">
                        <span class="actions-btn">
                            <i class="el-icon-edit"></i>编辑
                        </span>
                    </a>
                    <a class="icon-btn" @click="viewPre(el)">
                        <span class="actions-btn">
                            <i class="el-icon-view"></i>预览
                        </span>
                    </a>
                    <template>
                        <el-popconfirm
                        title="确定删除吗?"
                        @confirm="delSt(index)"
                        >
                            <a class="icon-btn" slot="reference">
                                <span class="actions-btn">
                                    <i class="el-icon-delete"></i>删除
                                </span>
                            </a>
                        </el-popconfirm>
                    </template>
                </div>
            </div>
            <div class="chapter-content" v-if="el.type === 4 || el.type === '4'">
                <div class="item-content" v-if="el.status">
                    <span class="section_index">资源{{index+1}}：</span>
                    <el-input v-model="name" :ref="'section_'+index" @blur="checkSection(index)" placeholder="请输入"></el-input>
                </div>
                <div v-else class="item-content"><i class="el-icon-s-claim"></i>测试{{index+1}}：{{el.name}}</div>
                <div class="item-actions">
                    <a class="icon-btn" @click="editName(index)">
                        <span class="actions-btn">
                            <i class="el-icon-edit"></i>编辑
                        </span>
                    </a>
                    <router-link target="_blank" class="icon-btn" :to="{path: '/exampaper/preview', query: {id: el.id}}">
                        <span class="actions-btn">
                            <i class="el-icon-view"></i>预览
                        </span>
                    </router-link>
                    <template>
                        <el-popconfirm
                                title="确定删除吗?"
                                @confirm="delSt(index)"
                        >
                            <a class="icon-btn" slot="reference">
                                <span class="actions-btn">
                                    <i class="el-icon-delete"></i>删除
                                </span>
                            </a>
                        </el-popconfirm>
                    </template>
                </div>
            </div>
            <div class="chapter-content" v-if="el.type === 5 || el.type === '5'">
                <div class="item-content" v-if="el.status">
                    <span class="section_index">项目{{index+1}}：</span>
                    <el-input v-model="name" :ref="'section_'+index" @blur="checkSection(index)" placeholder="请输入"></el-input>
                </div>
                <div v-else class="item-content"><i class="el-icon-menu"></i>项目{{index+1}}：{{el.name}}</div>
                <div class="item-actions">
                    <a class="icon-btn" @click="editName(index)">
                        <span class="actions-btn">
                            <i class="el-icon-edit"></i>编辑
                        </span>
                    </a>
                    <router-link target="_blank" class="icon-btn" :to="{path: '/admin/index/projectDetail', query: {id: el.id}}">
                        <span class="actions-btn">
                            <i class="el-icon-view"></i>预览
                        </span>
                    </router-link>
                    <template>
                        <el-popconfirm
                                title="确定删除吗?123"
                                @confirm="delSt(index)"
                        >
                            <a class="icon-btn" slot="reference">
                                <span class="actions-btn">
                                    <i class="el-icon-delete"></i>删除
                                </span>
                            </a>
                        </el-popconfirm>
                    </template>
                </div>
            </div>
            <div v-if="el.expand">
            </div>
            <div v-else>
                <leason-template :children="el.children"/>
            </div>
        </li>
        </draggable>
    </ul>
</template>
<script>
import draggable from "vuedraggable";
export default {
    name: 'leasonTemplate',
    props: {
        children: {
            required: true,
            type: Array
        }
    },
    components: {
        draggable
    },
    data() {
        return {
            name: '',
        }
    },
    methods: {
        handleCommand(command) {
            let _this = this;
            if (this.$store.state.undone) {
                this.$message({
                    type: 'warning',
                    message: '还有新增的节未完成设置~',
                });
                return false;
            }
            let section = {
                name: '',
                type: 2,
                children: [],
                status: true
            }
            this.children[command].children.unshift(section);
            // 标记未编辑节名称
            this.$store.state.undone++;
        },
        delSt(index) {
            this.children.splice(index, 1);
            if (this.$store.state.undone) {
                this.$store.state.undone--;
            }
        },
        editName(index) {
            let tmp = this.children[index];
            tmp.status = true;
            this.name = tmp.name;
            this.$set(this.children, index, tmp);
        },
        fileTypeF(type) {
            let html = '';
            switch (type) {
                case "mp4":
                    html = "<i class='iconfont'>&#xe682;</i>";
                    break;
                case "pptx":
                    html = "<i class='iconfont'>&#xe63a;</i>";
                    break;
                case "pdf":
                    html = "<i class='iconfont'>&#xe65b;</i>";
                    break;
                default:
                    html = "<i class='iconfont'>&#xe640;</i>";
                    break;
            }
            return html;
        },
        checkSection(index) {
            let _this = this;
            if (this.name.length === 0 || (Number(this.name) === 0 && this.name !== '0') || this.name.length > 30) {
                this.$message({
                    type: 'warning',
                    message: '请输入合法的节名称',
                    duration: 1000,
                    onClose: function() {
                        _this.$refs[`section_${index}`][0].focus();
                    }
                });
            } else {
                this.children[index].name = this.name;
                delete this.children[index].status;
                this.name = '';
                if (this.$store.state.undone) {
                    this.$store.state.undone--;
                }
            }
        },
        expandFunc(index) {
            let tmp = this.children[index];
            tmp.expand = !tmp.expand;
            this.$set(this.children, index, tmp);
        },
        viewPre(el) {
            if (el.resource_file_type.toLowerCase() === 'mp4') {
                window.open(el.file_preview_path);
            } else {
                let url = this.$api.pdfPreview + el.resource_id;
                window.open(url);
            }
        }
    } 
}
</script>
<style lang="scss" scoped>
    ul.menu-list {
        list-style: none;
        margin: 0 0 0 15px;
        padding: 0;
        .d-tr {
            border: 1px solid #66B1FF;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-bottom: 10px;
            div {
                display: none;
            }
            &::before {
                content: '\677e\5f00\9f20\6807\653e\5230\6b64\4f4d\7f6e';
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #B3D8FF;
                color: #409EFF;
            }
        }
        .list-item {
            background: #fff;
            cursor: move;
        }
        li.menu-chapter-list {
            .chapter-content {
                height: 42px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #c1c1c1;
                margin-bottom: 15px;
                .item-content {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-left: 10px;
                    flex: 1;
                    .section_index {
                        width: 70px;
                    }
                    i {
                        font-size: 16px;
                        margin-right: 5px;
                    }
                }
                .item-actions {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .icon-btn-expand, .icon-btn {
                        cursor: pointer;
                        font-size: 14px;
                        padding: 0 12px;
                        i {
                            margin-right: 5px;
                        }

                    }
                }
            }
        }
    }

</style>